import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "layout": "post",
  "title": "3 Steps to Building a Network",
  "author": "Anthony",
  "date": "2018-01-11T06:26:52.000Z",
  "categories": ["Military Transition"],
  "slug": "3-steps-to-building-a-network",
  "draft": false,
  "meta_title": "3 Steps to Building a Network"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`During my transition from active duty, I attended many career events and transition seminars that stressed the importance of networking and making connections. I didn't heed this advice.`}</p>
    <p>{`At first, I felt my ability to network was proven during my time in the military as I built relationships easily with peers and members of various battalion and bridge staff sections. In the military realm, these relationships helped me quickly obtain supplies that I required for mission success or sped up the processing time for random paperwork.`}</p>
    <p>{`However, I quickly learned that networking in the military and networking in the corporate world are two different animals. I should have paid attention to those speakers. So to save `}<em parentName="p">{`you`}</em>{` a little time and lessons hard-learned, let's breakdown the basics of networking outside of the military:`}</p>
    <p><strong parentName="p">{`Social Network`}</strong>{`: This network is your family and friends. The people who provide the emotional and moral support required as you enter this new phase of your life. Regardless of your experiences in the military, leaving a job with an almost guaranteed paycheck on the 1st and 15th of the month is stressful as you look for your next job in an uncertain market. This is even truer if you did not adequately plan your finances to cover your expenses between departing the military and landing your next job (`}<em parentName="p">{`more on this in a future post`}</em>{`. A well established social network of family and friends will be there to help support you during these times.`}</p>
    <p><strong parentName="p">{`Professional Network`}</strong>{`: Individuals focused on matters of business. These individuals can serve as mentors and help navigate you through your transition as you search for and/or prepare for entry into a new industry. The most well known professional networking tool is `}<a parentName="p" {...{
        "href": "http://www.linkedin.com/"
      }}>{`LinkedIn`}</a>{`. LinkedIn offers itself as an excellent source for finding people who are already established in your desired industry. Fostering relationships with those individuals can give you valuable insight into the industry, market, and connections. Building up this network not only helps prepare you for your new job but it can also open up new opportunities in the future. For example, someone within your professional network can introduce you to job openings that only an “insider” would know about and you would not hear of it otherwise. This network can also direct you towards classes or skills that you need to develop in order to be successful in your new job.`}</p>
    <p><strong parentName="p">{`Organizational Network:`}</strong>{` Organizations, trade groups, and professional associations related to your interested profession. This network avenue is excellent for learning the current and future state of the industry. By tapping into your organizational network you will be able to learn the intricate details of your industry, its best practices, and understand the future challenges and changes that will impact the industry and your job. I recommend that you join industry-related groups on LinkedIn, following their social media feeds, and when ready join their organizations allowing you access to their publications and insights. Additionally, attending industry forums and seminars will provide you great insight into your industry and new technologies about to enter the market.`}</p>
    <p>{`Networking is an essential component of your transition to best prepare you for your new role in civilian life. Building your support structure and network prior to transition will greatly assist you as you weigh your options and decide on the correct path for you – entering the workforce, enrolling in school, or even starting your own business. Your balanced network will help guide you, ensuring that you are making informed decisions and setting yourself and/or your family up for success.`}</p>
    <p>{`-`}{` Anthony`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      